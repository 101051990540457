import TikTokIcon from "@/assets/icons/TikTokIcon.jsx";
import InstagramIcon from "@/assets/icons/InstagramIcon.jsx";
import MetaIcon from "@/assets/icons/MetaIcon.jsx";
import PinterestIcon from "@/assets/icons/PinterestIcon.jsx";
import YoutubeIcon from "@/assets/icons/YoutubeIcon.jsx";
import AdultIcon from "@/assets/icons/AdultIcon.jsx";
import LiveIcon from "@/assets/icons/LiveIcon.jsx";
import RedditIcon from "@/assets/icons/RedditIcon.jsx";
import EtsyIcon from "@/assets/icons/EtsyIcon.jsx";
import EbayIcon from "@/assets/icons/EbayIcon.jsx";
import Scraper from "@/assets/icons/Scraped";
import Processed from "@/assets/icons/Processed";
import Indexed from "@/assets/icons/Indexed";
import XIcon from "@/assets/icons/XIcon.jsx";

const IconsMap = {
    x: (props) => <XIcon {...props} />,
    tiktok: (props) => <TikTokIcon {...props} />,
    facebook: (props) => <MetaIcon {...props} />,
    instagram: (props) => <InstagramIcon {...props} />,
    pinterest: (props) => <PinterestIcon {...props} />,
    youtube: (props) => <YoutubeIcon {...props} />,
    adult: (props) => <AdultIcon {...props} />,
    live: (props) => <LiveIcon {...props} />,
    reddit: (props) => <RedditIcon {...props} />,
    etsy: (props) => <EtsyIcon {...props} />,
    ebay: (props) => <EbayIcon {...props} />,
    scraped: (props) => <Scraper {...props}/>,
    processed: (props) => <Processed {...props}/>,
    indexed: (props) => <Indexed {...props}/>,
};

export default IconsMap;
